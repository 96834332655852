//  COOKIES PARAMS
export const BEARER = 'Bearer'
export const TOKEN = 'token'
export const PARTNERS = 'partners'
export const MODEL_TYPES = 'model_types'
export const SELECTED_PARTNERS = 'selected_partners'
export const SELECTED_SORTING = 'selected_sorting'
export const CREATED = 'created'
export const USER_DATA = 'user_data'
export const LANGUAGE = 'language'
export const HEADER_MODULES = 'header_modules'
export const SIDEBAR_MODULES = 'sidebar_modules'
export const PERMISSIONS = 'permissions'

// HEADER MODULES
export const NOTIFICATIONS_MODULE = 'notifications'

// SIDEBAR MODULES
export const DASHBOARD_MODULE = 'dashboard'
export const ARCHIVES_MODULE = 'archives'
export const STATUS_RECORDS_MODULE = 'status_records'
export const USERS_MODULE = 'users'
export const BOILERS_MODULE = 'boilers'
export const REPORTS_MODULE = 'reports'
export const FIRMWARE_MODULE = 'firmware'
export const ALTA_FIRMWARE_MODULE = 'alta_firmware'

//  PATHS
export const INDEX = '/'
export const FORGOT_PASSWORD = '/forgot'
export const RESET_PASSWORD = '/reset'
export const CREATE_PASSWORD = '/create-password'
export const DASHBOARD = '/dashboard'
export const TERMS_AND_CONDITIONS = '/terms-and-conditions'
export const PRIVACY = '/privacy'
export const ARCHIVES = '/archives'
export const STATUS_RECORDS = '/status'
export const USERS = '/users'
export const BOILERS = '/boilers'
export const REPORTS = '/reports'
export const FIRMWARE = '/gateway-firmware'
export const ALTA_FIRMWARE = '/alta-controller-firmware'
export const NOTIFICATIONS = '/notifications'
export const BOILER_STATUS = '/dashboard/boiler-status'
export const PROFILE = '/profile'

// API URLS
export const LOGIN_URL = '/users/signin-webportal'
export const FORGOT_URL = '/users/forgot-password'
export const RESET_URL = '/users/reset-password'
export const USER_PROFILE_URL = '/users/profile/{userId}'
export const GET_BOILERS_URL = '/boilers'
export const GET_BOILER_URL = '/boilers/'
export const GET_ARCHIVES_BY_ID_URL = '/files/reports'
export const GET_MANUALS_URL = '/files/manuals'
export const GET_PHOTOS_URL = '/files/photos'
export const DELETE_FILE_URL = '/files/'
export const GET_ARCHIVES_URL = '/files/archives'
export const GET_STATUS_RECORDS_URL = '/files/status-records'
export const GET_BOILERS_MANAGEMENT_URL = '/boilers/boiler-registration'
export const GET_BOILERS_MANAGEMENT_DETAILS_URL = '/boilers/boiler-registration/'
export const GET_GATEWAYS_FIRMWARE_URL = '/gateways/firmware'
export const GET_ALTA_FIRMWARE_URL = '/alta-controller/firmware'
export const GET_GATEWAYS_URL = '/gateways'
export const UPLOAD_FIRMWARE = '/files/upload-firmware'
export const UPLOAD_ALTA_FIRMWARE = '/files/upload-alta-firmware'
export const GET_USERS_URL = '/users/statuses'
export const CHANGE_STATUS_USER_URL = '/users/{userId}/change-status'
export const SEND_INVITE_URL = '/users/{userId}/send-invite'
export const ENABLE_WEBPORTAL_URL = '/users/enable-webportal'
export const GET_ROLES_URL = '/users/roles'
export const GET_BRANDS_URL = '/partner'
export const GET_MODEL_TYPES_URL = '/boilers/types'
export const GET_BRAND_DATA_URL = '/partner/info?brand={brand}'
export const GET_REPRESENTATIVES_URL = '/users/representatives'
export const GET_COMPANIES_URL = '/users/companies'
export const GET_USER_BY_ID_URL = '/users/'
export const GET_TECHNICIAN_DATA_URL = '/users/technician/'
export const PUT_UPGRADE_TECHNICIAN_URL = '/users/technician/'
export const SAVE_OR_UPDATE_USER_URL = '/users/webportal'
export const CREATE_COMPANY_URL = '/users/companies'
export const CHANGE_PASSWORD_URL = '/users/{userId}/change-password'
export const CHANGE_LANGUAGE_URL = '/users/{userId}/language'
export const TOGGLE_HO_DATA = '/users/{userId}/toggle-hide-data'
export const DELETE_USER_URL = '/users/{userId}/delete'
export const DELETE_BOILER_BY_ID_URL = '/boilers/'
export const GET_ALARM_TYPES_URL = '/boilers/alarm-types'
export const GET_ALARMS_URL = '/boilers/alarms'
export const GET_ALARMS_REPORT_URL = '/boilers/alarms-report'
export const GET_SERIALS_URL = '/boilers/serials'
export const GET_USER_STATUSES_URL = '/users/user-statuses'
export const GET_ENROLLED_AUDIENCE_URL = '/users/enrolled-audience'

// WEBSOCKET URLS
export const SOCKET_TRANSPORTS = ['xhr-streaming']
export const SOCKET_PATH = '/sockets'
export const DASHBOARD_SEND_TO = '/app/user'
export const DASHBOARD_SUBSCRIBE_TO = '/topic/user'
export const BOILER_SEND_TO = '/app/topic/boiler/{boilerSerialNumber}/connection'
export const BOILER_SUBSCRIBE_TO = '/topic/boiler/{boilerSerialNumber}/connection'

// PERMISSIONS
export const PENDING_USERS_PERMISSION = 'pendingUsersAllowed'
export const ADD_USERS_PERMISSION = 'addUsersAllowed'
export const HISTORY_ALARMS_REPORT_PERMISSION = 'historyAlarmsReport'
export const ENROLLED_AUDIENCE_REPORT_PERMISSION = 'enrolledAudienceReport'
export const USERS_WITH_BOILERS_REPORT_PERMISSION = 'usersWithBoilersReport'
export const TIME_SINCE_LAST_CONNECTION_REPORT_PERMISSION = 'timeSinceLastConnectionReport'
export const DELETE_ARCHIVES_PERMISSION = 'deleteArchivesAllowed'
export const DELETE_STATUS_RECORDS_PERMISSION = 'deleteStatusRecordsAllowed'
export const DELETE_BOILERS_PERMISSION = 'deleteBoilersAllowed'

// REGEX
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/g
export const DATE_FORMAT = 'MM/DD/YYYY'

// OTHERS
export const CONTRACTOR_ROLE = 'ROLE_CONTRACTOR'
export const TECHNICIAN_ROLE = 'ROLE_TECHNICIAN'
export const ENGINEERING_ADMIN_ROLE = 'ROLE_ENGINEERING_ADMIN'
export const USER_ADMIN_ROLE = 'ROLE_USER_ADMIN'
export const ADMIN_ROLE = 'ROLE_ADMIN'
export const HO_ROLE = 'ROLE_HOME_OWNER'
export const REP_ROLE = 'ROLE_REPRESENTATIVE'
export const CONNECTION_LOCAL = 'LOCAL'
export const CONNECTION_REMOTE = 'REMOTE'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
